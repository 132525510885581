@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    article#content * {
        @apply w-full !max-w-full;
    }

    article#content > * {
        @apply w-full !max-w-full;
    }

    article#content > * {
        @apply w-full !max-w-full space-y-4 break-words;
    }

    article#content figure iframe {
        @apply !w-[1000px]  lg:!h-[500px];
    }

    article#content p {
        @apply mb-4;
        @apply text-zinc-700;
    }

    article#content h2,
    h3 {
        @apply text-2xl font-bold text-neutral-800 lg:text-xl3;
    }
    article#content img {
        @apply rounded-md;
    }
    article#content ol {
        @apply list-decimal;
    }
}
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-button-prev {
    display: inline;
}
.swiper-button-next {
    display: inline;
}
.swiper-pagination {
    position: relative;
    top: 10px;
    z-index: 10;
}
.swiper-slide-shadow.swiper-slide-shadow-cards {
    display: none;
}

.top {
    width: 100%;
    height: 100px;
    clip-path: polygon(100% 100%, 0% 100%, 100% 10%);
}

.bottom {
    width: 100%;
    height: 100px;
    /* clip-path: polygon(0% 40%,0% 100%,100% 100%); */
    /* clip-path: polygon(0% 73%, 100% 100%, 100% 74%); */
    clip-path: polygon(0% 10%, 0% 100%, 100% 100%);
}

.video-js {
    width: 800px !important;
    height: 360px !important;
    border-radius: 0.5rem !important;
    overflow: hidden !important;
    @media only screen and (max-width: 600px) {
        height: 220px !important;
        width: 330px !important;
    }
    @media only screen and (max-width: 1080px) and (min-width: 600px) {
        width: 600px !important;
        height: 270px !important;
    }
    /* w-[351px] h-[220px] */
    .vjs-progress-control {
        position: absolute !important;
        top: -4rem !important;
        left: 0 !important;
        align-items: normal !important;
        width: 80% !important;
        padding-top: 3rem !important;
        @media only screen and (max-width: 600px) {
            width: 70% !important;
        }
        /* @media only screen and (min-width: 600px) {
            width: 80% !important;
        } */
    }
    .vjs-progress-control.dark {
        position: absolute !important;
        top: -4rem !important;
        color: black !important;
        left: 0 !important;
        align-items: normal !important;
        width: 80% !important;
        padding-top: 3rem !important;
        @media only screen and (max-width: 600px) {
            width: 70% !important;
        }
        /* @media only screen and (min-width: 600px) {
            width: 80% !important;
        } */
    }
    .vjs-time-control {
        display: none !important;
    }

    .vjs-control-bar {
        background-color: rgba(0, 0, 0, 0) !important;
        font-size: 1.4rem;
        height: 5rem;
        bottom: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }
    .vjs-control-bar.dark {
        background-color: #0d0d0d !important;
        font-size: 1.4rem;
        height: 5rem;
        bottom: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .vjs-slider-bar {
        background-color: #178bbc !important;
    }

    .vjs-slider {
        background-color: #5f5f5f !important;
    }

    .vjs-load-progress {
        background-color: #178bbc !important;
    }
    .vjs-paused {
        background-image: url("/Icons/PlayerIcons/play.svg") !important;
        background-repeat: no-repeat;
        background-size: 30%;
        width: 100%;
        height: 100%;
        background-position-x: center;
        background-position-y: center;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            width: 4rem !important;
            height: 4rem !important;
        }
    }
    .vjs-playing {
        background-image: url("/Icons/PlayerIcons/pause.svg") !important;
        background-repeat: no-repeat;
        background-size: 40%;
        width: 100%;
        height: 100%;
        background-position-x: center;
        background-position-y: center;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            width: 4rem !important;
            height: 4rem !important;
        }
    }
    .vjs-big-play-button {
        background-color: rgba(0, 0, 0, 0) !important;
        border: none !important;
        width: 100%;
        height: 100%;
        background-image: url("/Icons/PlayerIcons/play.svg") !important;
        background-repeat: no-repeat;
        background-size: 40%;
        background-position-x: center;
        background-position-y: center;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            .vjs-icon-placeholder {
                display: none !important;
            }
        }
    }
    .vjs-mute-control {
        background-color: rgba(0, 0, 0, 0) !important;
        border: none !important;
        width: 100%;
        height: 100%;
        background-image: url("/Icons/PlayerIcons/sound.svg") !important;
        background-repeat: no-repeat;
        background-size: 40%;
        background-position-x: center;
        background-position-y: center;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            display: none !important;
        }
    }
    .vjs-mute-control.vjs-vol-0 {
        background-image: url("/Icons/PlayerIcons/mute.svg") !important;
    }
    .vjs-mute-control.vjs-vol-1 {
        background-image: url("/Icons/PlayerIcons/volMin.svg") !important;
    }
    .vjs-mute-control.vjs-vol-2 {
        background-image: url("/Icons/PlayerIcons/volMin.svg") !important;
    }

    .vjs-slider-active {
        color: black !important;
    }
    .vjs-fullscreen-control {
        background-color: rgba(0, 0, 0, 0) !important;
        border: none !important;
        width: 100%;
        height: 100%;
        position: absolute !important;
        right: 0 !important;
        background-image: url("/Icons/PlayerIcons/fullscreen.svg") !important;
        background-repeat: no-repeat;
        background-size: 40%;
        background-position-x: center;
        background-position-y: center;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            width: 4rem !important;
            height: 4rem !important;
        }
    }
    .sub-button {
        background-color: rgba(0, 0, 0, 0) !important;
        border: none !important;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 40%;
        background-position-x: center;
        background-position-y: center;
        /* position: absolute !important; */
        /* right: 100px !important; */
        /* right: 160px !important; */
        text-wrap: normal !important;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            width: 4rem !important;
            height: 4rem !important;
            display: absolute !important;
            right: 100px !important;
        }
    }
    .sub-button.sub-button-off {
        background-image: url("/Icons/PlayerIcons/CCOff.svg") !important;
    }
    .sub-button.sub-button-on {
        background-image: url("/Icons/PlayerIcons/CC.svg") !important;
    }
    .vjs-subs-caps-button {
        display: none !important;
    }
    .vjs-picture-in-picture-control {
        display: none !important;
    }
    .vjs-skip-backward-10 {
        background-image: url("/Icons/PlayerIcons/back.svg") !important;
        background-repeat: no-repeat;
        background-size: 40%;
        background-position-x: center;
        background-position-y: center;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            width: 4rem !important;
            height: 4rem !important;
            display: absolute !important;
            left: -20px !important;
        }
    }
    .vjs-skip-forward-10 {
        background-image: url("/Icons/PlayerIcons/forward.svg") !important;
        background-repeat: no-repeat;
        background-size: 40%;
        background-position-x: center;
        background-position-y: center;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            width: 4rem !important;
            height: 4rem !important;
            display: absolute !important;
            left: -30px !important;
        }
    }
    .vjs-current-time {
        display: block !important;
        top: -4rem !important;
        right: 55px !important;
        position: absolute !important;
        padding-top: 1rem !important;
        @media only screen and (max-width: 600px) {
            font-size: 0.8em !important;
            padding-top: 1.5rem !important;
        }
    }
    .vjs-current-time.dark {
        display: block !important;
        color: black !important;
        top: -4rem !important;
        right: 55px !important;
        position: absolute !important;
        padding-top: 1rem !important;
        @media only screen and (max-width: 600px) {
            font-size: 0.8em !important;
            padding-top: 1.5rem !important;
        }
    }
    .vjs-duration {
        display: block !important;
        top: -4rem !important;
        right: 0 !important;
        position: absolute !important;
        padding-top: 1rem !important;
        @media only screen and (max-width: 600px) {
            font-size: 0.8em !important;
            padding-top: 1.5rem !important;
        }
    }
    .vjs-duration.dark {
        display: block !important;
        color: black !important;
        top: -4rem !important;
        right: 0 !important;
        position: absolute !important;
        padding-top: 1rem !important;
        @media only screen and (max-width: 600px) {
            font-size: 0.8em !important;
            padding-top: 1.5rem !important;
        }
    }
    .vjs-time-divider {
        display: block !important;
        position: absolute !important;
        top: -4rem !important;
        right: 45px !important;
        padding-top: 1rem !important;
        @media only screen and (max-width: 600px) {
            font-size: 0.8em !important;
            padding-top: 1.5rem !important;
        }
    }
    .vjs-time-divider.dark {
        display: block !important;
        color: black !important;
        position: absolute !important;
        top: -4rem !important;
        right: 45px !important;
        padding-top: 1rem !important;
        @media only screen and (max-width: 600px) {
            font-size: 0.8em !important;
            padding-top: 1.5rem !important;
        }
    }
    .vjs-playback-rate {
        display: block !important;
        position: absolute !important;
        right: 80px;
        background-image: url("/Icons/PlayerIcons/playback.svg") !important;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        width: 60px;
        .vjs-icon-placeholder {
            display: none !important;
        }
        .vjs-playback-rate-value {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            width: 4rem !important;
            height: 4rem !important;
            right: 50px !important;
        }
    }
    .playlist-button {
        position: absolute !important;
        right: 240px;
        background-image: url("/Icons/PlayerIcons/playlist.svg") !important;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            display: none !important;
        }
        /* @media only screen and (min-width: 600px) {
            display: none !important;
        } */
    }
    .next-video-button {
        position: absolute !important;
        right: 320px;
        background-image: url("/Icons/PlayerIcons/nextVideo.svg") !important;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        .vjs-icon-placeholder {
            display: none !important;
        }
        @media only screen and (max-width: 600px) {
            display: none !important;
        }
    }
    .vjs-mute-control {
        @media screen and (max-width: 600px) {
            display: none !important;
        }
    }

    .video-title {
        display: flex !important;
        justify-content: center !important;
        color: white !important;
        text-wrap: normal !important;
        align-items: center !important;
        position: absolute !important;
        left: 30% !important;
        right: 30% !important;
        @media screen and (max-width: 600px) {
            display: none !important;
        }
        /* @media only screen and (min-width: 600px) {
            display: none !important;
        } */
    }
    .playlist-item.hover-off {
        display: none !important;
    }
    .playlist-item.hover-on {
        position: absolute !important;
        bottom: 60px;
        right: 240px;
        z-index: 1000;
        border: none !important;
        .bg {
            background-color: #202020;
        }
        .header {
            padding-top: 1.25rem;
            padding-left: 1.25rem;
            flex-basis: 0px;
            flex-shrink: 1;
            font-weight: 400;
            line-height: 1.5;
            color: #ffffff;
            font-size: 19px;
        }

        .outline {
            display: inline-flex;
            border: none !important;
            z-index: 10;
            padding: 1.25rem;
            gap: 0.75rem;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            outline-style: none;
            box-shadow:
                0 1px 3px 0 rgba(0, 0, 0, 0.1),
                0 1px 2px 0 rgba(0, 0, 0, 0.06);
            height: 140px;
            background: #202020;

            @media (min-width: 1024px) {
                width: 440px;
            }
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.5rem;
                height: 1.5rem;
            }
        }
        .center {
            display: flex;
            gap: 0.5rem;
            justify-content: flex-start;
            align-items: center;
        }
        .image {
            position: relative;
            border-radius: 0.5rem;
            width: 10rem;
            height: 100px;
            .img {
                position: absolute;
                top: 0;
                left: 0;
                width: 10rem;
                height: 100px;
            }
            .duration {
                position: absolute;
                font-weight: 400;
                color: #ffffff;
                width: 318px;
                left: 8px;
                top: 74px;
                color: 13px;
            }
        }
        .text {
            display: inline-flex;
            padding-bottom: 1.25rem;
            flex-basis: 0px;
            flex-direction: column;
            gap: 0.5rem;
            justify-content: flex-start;
            align-items: flex-start;
            flex-grow: 1;
            .justify {
                display: inline-flex;
                gap: 0.25rem;
                flex-shrink: 1;
                justify-content: flex-start;
                align-items: center;
                align-self: stretch;
                flex-shrink: 1;
                .title {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    flex-basis: 0px;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    flex-grow: 1;
                    font-weight: 700;
                    color: #ffffff;
                }
            }
            .description {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                align-self: stretch;
                font-weight: 400;
                line-height: 1;
                font-size: 13px;
                --tw-text-opacity: 0.7;
                color: rgb(245 245 245 / var(--tw-text-opacity));
            }
        }
    }
    .playlist.hover-off {
        display: none !important;
    }
    .playlist.hover-on {
        position: absolute;
        z-index: 50;
        bottom: 60px;
        right: 240px;

        .outline {
            overflow-y: scroll;
            border-top-width: 1px;
            height: 38rem;
            /* width: 100%; */
            background-color: #202020;
            outline-style: none;

            @media (min-width: 768px) {
                height: 15rem;
            }
            @media (min-width: 1024px) {
                height: 24rem;
            }
            .pointer {
                cursor: pointer;
            }
            .box {
                display: inline-flex;
                padding-left: 1.25rem;
                padding-right: 1.25rem;
                padding-bottom: 0.5rem;
                padding-top: 1.25rem;
                gap: 0.25rem;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 52px;
                outline-style: none;
                background-color: rgb(38 38 38 / var(--tw-bg-opacity));
                @media (min-width: 1024px) {
                    width: 440px;
                }
                .img {
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 4px;
                }
                .name {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    flex-basis: 0px;
                    flex-grow: 1;
                    flex-shrink: 1;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #ffffff;
                    font-size: 19px;
                }
            }
            .modules.module-hide {
                display: none;
            }
            .modules.module-show {
                /* padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                padding-left: 1.25rem;
                padding-right: 1.25rem;
                padding-top: 0; */
                padding: 0.5rem 1.25rem;
                margin-top: 1.25rem;
                gap: 0.25rem;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100vh;
                cursor: pointer;

                @media (min-width: 1024px) {
                    /* padding-top: 0;
                    padding-bottom: 0;
                    padding-top: 0.5rem; */
                    padding: 0.5rem 0;
                    width: 440px;
                }
                .module-name {
                    padding: 0.5rem;
                    margin-top: 1.25rem;
                    width: 100%;
                    font-size: 1.125rem;
                    line-height: 1.75rem;
                    font-weight: 700;
                    color: #ffffff;
                }
                .module-name:hover {
                    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
                }
            }
            .videos.videos-hide {
                display: none;
            }
            .video-description.title-show {
                color: #ffffff !important;
                text-align: center !important;
                font-size: 1.125rem !important;
                line-height: 1.75rem !important;
                font-weight: 700 !important;
                padding: 0.5rem !important;
                margin-top: 1.25rem !important;
                width: 100% !important;
            }
            .video-description.title-hidden {
                display: none !important;
            }
            .videos.videos-show {
                .outline {
                    display: flex;
                    overflow-y: hidden;
                    border: none !important;
                    outline-style: none;
                    z-index: 10;
                    padding: 1.25rem;
                    gap: 0.75rem;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 100%;
                    outline-style: none;
                    box-shadow:
                        0 1px 3px 0 rgba(0, 0, 0, 0.1),
                        0 1px 2px 0 rgba(0, 0, 0, 0.06);
                    height: 140px;
                    background: #202020;

                    @media (min-width: 1024px) {
                        width: 440px;
                    }
                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
                .center {
                    display: flex;
                    gap: 0.5rem;
                    justify-content: flex-start;
                    align-items: center;
                }
                .image {
                    position: relative;
                    border-radius: 0.5rem;
                    width: 10rem;
                    height: 100px;
                    .img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 10rem;
                        height: 100px;
                    }
                    .duration {
                        position: absolute;
                        font-weight: 400;
                        color: #ffffff;
                        width: 318px;
                        left: 8px;
                        top: 74px;
                        color: 13px;
                    }
                }
                .text {
                    display: inline-flex;
                    padding-bottom: 1.25rem;
                    flex-basis: 0px;
                    flex-direction: column;
                    gap: 0.5rem;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-grow: 1;
                    .justify {
                        display: inline-flex;
                        gap: 0.25rem;
                        flex-shrink: 1;
                        justify-content: flex-start;
                        align-items: center;
                        align-self: stretch;
                        flex-shrink: 1;
                        .title {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            flex-basis: 0px;
                            font-size: 1rem;
                            line-height: 1.5rem;
                            flex-grow: 1;
                            font-weight: 700;
                            color: #ffffff;
                        }
                    }
                    .description {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        align-self: stretch;
                        font-weight: 400;
                        line-height: 1;
                        font-size: 13px;
                        --tw-text-opacity: 0.7;
                        color: rgb(245 245 245 / var(--tw-text-opacity));
                    }
                }
            }
        }
    }
}
.vertical-timeline.vertical-timeline.vertical-timeline-element-icon.bounce-in::before {
    /* border: none !important;     */
    border: #202020 !important;
}

.vertical-timeline-element.vertical-timeline-element-content p {
    font-size: 22px;
}

.mantine-Spoiler-control {
    color: black;
    text-decoration: underline;
}
.vertical-timeline.vertical-timeline--two-columns:before {
    background-color: #d7d7d7;
}
.vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column-left::before {
    background-color: #d7d7d7;
}
